
























import { Component } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import MediaContent from '@/components/MediaContent.vue'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import BasicIntersectionObservable from '@/mixins/BasicIntersectionObservable'
import CheatSheet from '@/components/CheatSheet.vue'

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        ArrowDownNextSlide,
        CheatSheet
    }
})
export default class SlideMediaGrid extends mixins(Slide, BasicIntersectionObservable) {
    $refs!: {
        items: Array<HTMLElement>
        medias: Array<MediaContent>
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemMediaGrid
    }

    get layout() {
        return this.walkerItem.layout || null
    }

    get classNames() {
        const classNames = [this.$style.root]

        if (this.walkerItem.layout) {
            classNames.push(this.$style['root--' + this.walkerItem.layout])
        } else {
            classNames.push(this.$style['root--columned_media_left'])
        }

        return classNames
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.$refs.medias) {
            timeline.fromTo(
                this.$refs.items,
                {
                    y: 60,
                    opacity: 0
                },
                {
                    y: 0,
                    opacity: 1,
                    stagger: 0.1,
                    duration: 1.2,
                    ease: 'power3'
                },
                0.8
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        if (this.$refs.medias) {
            timeline.fromTo(
                this.$refs.items,
                {
                    y: 100,
                    opacity: 0
                },
                {
                    y: 0,
                    opacity: 1,
                    stagger: 0.5,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }
    }

    /**
     * @override
     */
    onObservableEnter() {
        this.$refs.medias?.forEach(media => media.play())
    }

    /**
     * @override
     */
    onObservableLeave() {
        this.$refs.medias?.forEach(media => media.pause())
    }
}
